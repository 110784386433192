"use strict";
import { notesLayer as notesLayerStore } from "../store";
import getNotes from "../../api/get";
export default async function (map) {
    let layer;
    notesLayerStore.subscribe(value => {
        layer = value;
    });
    const json = await getNotes(map.getBounds());
    if (json !== null) {
        layer.addData(json);
    }
    return json;
}
