"use strict";
import L from "leaflet";
import icons from "./icons";
import popupContent from "./popup";
export default function (feature, latlng) {
    const { status } = feature.properties;
    const marker = L.marker(latlng, {
        icon: icons[status]
    });
    const popup = popupContent(feature);
    marker.bindPopup(popup, {});
    return marker;
}
