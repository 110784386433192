"use strict";
import { GeoJSON } from "leaflet";
import styleFunction from "./style";
export default function () {
    return new GeoJSON(null, {
        filter: (feature) => {
            return feature.properties.comments.length > 0;
        },
        pointToLayer: (feature, latlng) => {
            return styleFunction(feature, latlng);
        }
    });
}
