<style>
  div {
    border-bottom: 1px solid gray;
  }

  h1 {
    margin-top: 0;
    margin-bottom: 16px;
  }
</style>

<div>
  <h1>
    <i class="mi-circle-warning"></i>
    OSM Report
  </h1>
</div>